import { API, i18n } from "@project/shared";

export const GetArticleList = ({ pageParam, queryKey }): Promise<any> => {
  const tagId = queryKey[1];
  const pageNum = queryKey[2];
  const pageSize = queryKey[3];
  const city = queryKey[4];

  return API.get("/users/articles", {
    params: {
      language: i18n.language.startsWith("ja") ? "ja" : "en",
      tagId: tagId == 0 ? undefined : tagId,
      page: pageParam ? pageParam : pageNum || 1,
      pageSize: pageSize || 6,
      city: city,
    },
  });
};

export const GetArticle = (): Promise<any> => {
  return API.get("/users/articles", {
    // params: {
    //   language: i18n.language.startsWith("ja") ? "ja" : "en",
    // },
  });
};
export const GetArticleEn = (): Promise<any> => {
  return API.get("/users/articles", {
    params: {
      language: "en",
    },
  });
};

export const FetchTag = async ({ queryKey }) => {
  const cityCode = queryKey[1];
  const data: any = await API.get("/users/tags", {
    params: {
      city: cityCode,
      language: i18n.language.startsWith("ja") ? "ja" : "en",
    },
  });
  const refactored = [
    { id: "0", name: i18n.language.startsWith("en") ? "All" : "全て" },
    ...data?.data,
  ];

  return refactored;
};

export const GetFeaturedArticle = ({ queryKey }) => {
  const cityCode = queryKey[1];
  return API.get("/users/featured-articles", {
    params: {
      city: cityCode,
      language: i18n.language.startsWith("ja") ? "ja" : "en",
    },
  });
};

export const getArticleDetails = (id, lang, isAdmin) => {
  const slug = id;
  return API.get(`/users/articles/${slug}`, {
    params: {
      language: i18n.language.startsWith("ja") ? "ja" : lang,
      fromAdmin: isAdmin,
    },
  });
};

export const GetSimilarArticles = ({ pageParam, queryKey }): Promise<any> => {
  const id = queryKey[1];
  return API.get(`/users/similar-articles/${id}`, {
    params: {
      language: i18n.language.startsWith("ja") ? "ja" : "en",
      page: pageParam ? pageParam : 1,
      pageSize: 4,
    },
  });
};
