import React from "react";
import { styled } from "linaria/react";
import { Card } from "antd";
import { theme } from "@project/shared/src/theme";
import Play from "../../../assets/play.svg";

import Link from "next/link";

const StyledCard = styled(Card)`
  border-radius: 10px;
  background: ${theme.white};
  border: none;

  & .ant-card-body {
    padding: 16px 1px;
  }
  @media (max-width: 1440px) and (min-width: 769px) {
    max-width: 282px;
  }

  @media (max-width: 768px) {
    & .ant-card-body {
      padding-bottom: 0px;
    }
  }
`;
const StyledImage = styled.img`
  max-height: 187px;
  object-fit: cover;
  width: 100%;
  border-radius: 8px 8px 0px 0px !important;

  @media (max-width: 768px) {
    border-radius: 10px 10px 0px 0px !important;
  }
`;

const StyledTitle = styled.span`
  ${theme.typography.titleSmall}
  color: ${theme.gray0};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0px;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    margin-bottom: 8px;
    ${theme.typography.bodySmall}
  }
`;
const StyledSubHeading = styled.span`
  ${theme.typography.caption}
  color: ${theme.gray1};

  @media (max-width: 768px) {
    color: ${theme.grey2};
  }
`;
const PlayWrapper = styled.div`
  display: flex;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  cursor: pointer;

  @media (max-width: 575px) {
    & svg {
      width: 40px;
      height: 40px;
    }
  }
`;
const StyledWrapper = styled.div`
  position: relative;
  cursor: pointer;
  & :hover {
    & .title {
      text-decoration: underline;
    }
  }
`;

const ImageWrapper = styled.div`
  position: relative;
`;
interface ArticleCardProps {
  image?: string;
  title?: string;
  name?: string;
  hasVideo?: boolean;
  slug: string;
}
export default function ArticleCard({
  image,
  title,
  name,
  hasVideo,
  slug,
}: ArticleCardProps) {
  return (
    <StyledWrapper>
      <Link href={`/articles/${slug}`}>
        <a>
          <StyledCard
            cover={
              <ImageWrapper>
                <StyledImage
                  alt="article card image"
                  src={image || "/article.svg"}
                  className="card-image"
                  width={284}
                  height={187}
                />
                {hasVideo && (
                  <PlayWrapper>
                    <Play />
                  </PlayWrapper>
                )}
              </ImageWrapper>
            }
          >
            <StyledTitle className="title">{title}</StyledTitle>
            <StyledSubHeading>{name}</StyledSubHeading>
          </StyledCard>
        </a>
      </Link>
    </StyledWrapper>
  );
}
